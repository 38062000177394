import React, { useEffect, useState } from "react";
import SwiperCore, { Navigation, Pagination, EffectCoverflow, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "./Gallery.css";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";


// Initialize Swiper Core Components
SwiperCore.use([Navigation, Pagination, EffectCoverflow, Autoplay]);

export default function Gallery() {
    const [gallery, setGallery] = useState([]); // Initialize with an empty array
  const [isLoading, setIsLoading] = useState(true);  // Loading state
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(
          "https://jsr-backend-p4sz.onrender.com/Gallery"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch.");
        }
        const data = await response.json();
        setGallery(Object.values(data).slice(1, 14));
        setIsLoading(false); // Set loading to false after data arrives
      } catch (error) {
        console.error("There was an error fetching the data", error);
      }
    };

    fetchProducts();
  }, []);
  let arr = [1,2,3,4,5,6,7,8,9,10];

  return (
    <div className="lg:my-24 mx-10">
        <div className="flex items-center justify-between w-full mb-10">
      {/* Empty div to balance the center alignment */}
      <div className="w-1/3"></div>

      {/* Gallery title (centered) */}
      <h1 className="uppercase font-semibold text-2xl sm:text-3xl lg:text-4xl text-gray-800 text-center w-1/3">
        Gallery
      </h1>

      {/* View All Button (right-aligned) */}
      <div className="w-1/3 flex justify-end">
        <button  onClick={() => navigate("/our-gallery")} className="font-semibold flex items-center gap-2">
          <span>View All</span>
          <span>
            <FaArrowRight color="blue" />
          </span>
        </button>
      </div>
    </div>

      { 
      // isLoading ? (
      //     <p>Loading...</p>
      //   ) :
         (
          <PhotoProvider>
           <Swiper
  className="tranding-slider"
  effect="coverflow"
  grabCursor={true}
  centeredSlides={true}
  slidesPerView="auto"
  loop={true}
  autoplay={{
    delay: 2000,
    disableOnInteraction: false,
  }}
  coverflowEffect={{
    rotate: 0,
    stretch: 0,
    depth: 100,
    modifier: 2.5,
  }}
  pagination={{ clickable: true }}
  navigation={{
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  }}
  initialSlide={1} // 💡 Starts from the second slide
>

              {/* {gallery &&
                gallery.map((url, index) => (
                  <SwiperSlide key={index} className="tranding-slide">
                    <PhotoView src={url}>
                      <img src={url} alt={`Slide ${index}`} />
                    </PhotoView>
                  </SwiperSlide>
                ))} */}
                {
                  arr.map((i,index)=>(
                    <SwiperSlide key={index} className="tranding-slide ">
                    <PhotoView src={`gallery(${i}).jpg`}>
                      <img className="object-cover" src={`gallery(${i}).jpg`} alt={`Slide ${index}`} />
                    </PhotoView>
                  </SwiperSlide>
                  ))

                }
            </Swiper>
          </PhotoProvider>
        )
      }
    </div>
  );
}
