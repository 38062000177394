import React, { useState, useRef } from "react";
import SwiperCore, { EffectCoverflow, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";



SwiperCore.use([EffectCoverflow, Autoplay]);

export default function GalleryPage() {
  const [activeTab, setActiveTab] = useState("photos");
  const [selectedEvent, setSelectedEvent] = useState(null);
  const swiperRef = useRef(null);
  const [modalImage, setModalImage] = useState(null);

  // Events Data
  const events = [
    {
      id: 1,
      image: "gallery(5).jpg",
      name: "Diwali Celebration",
      date: "30-Oct-2024",
      description:
        "A night filled with live music performances and incredible energy.",
      photos: [
        "diwali/diwali1.jpg",
        "diwali/diwali2.jpg",
        "diwali/diwali3.jpg",
        "diwali/diwali4.jpg",
        "diwali/diwali5.jpg",
      ],
    },
    {
      id: 2,
      image: "gallery(3).jpg",
      name: "Independence Day Celebration",
      date: "15-Aug-2024",
      description:
        "Experience the creativity of talented artists showcasing their masterpieces.",
      photos: [
        "independend/Independence1.jpg",
        "independend/Independence2.jpg",
        "independend/Independence3.jpg",
        "independend/Independence4.jpg",
        "independend/Independence5.jpg",
        "independend/Independence6.jpg",
        "independend/Independence7.jpg",
        "independend/Independence8.jpg",
        "independend/Independence9.jpg",
        "independend/Independence10.jpg",
        "independend/Independence11.jpg",
        "independend/Independence12.jpg",
        "independend/Independence13.jpg",
      ], // Using logo2 and logo3
    },
    {
      id: 3,
      image: "party/party2.jpg",
      name: "BirthDay Parties",
      // date: "14-May-2025",
      description:
        "A paradise for food lovers featuring diverse cuisines and flavors.",
      photos: [
        "party/party2.jpg",
        "party/party1.jpg",
        "party/party3.jpg",
        "party/party4.jpg",
        "party/party5.jpg",
      ], // Using logo4 and logo5
    },
    {
      id: 4,
      image: "gallery(4).jpg",
      name: "Annual Trip",
      date: "13-May-2024",
      description:
        "Stay updated with the latest advancements in technology and innovation.",
      photos: [
        "annual/annual1.jpg",
        "annual/annual2.jpg",
        "annual/annual3.jpg",
        "annual/annual4.jpg",
        "annual/annual5.jpg",
        "annual/annual6.jpg",
        "annual/annual7.jpg",
        "annual/annual8.jpg",
      ], // Using logo6 and logo7
    },
    {
      id: 5,
      image: "Holi/holi1.jpg",
      name: "Holi Celebration",
      date: "15-Mar-2025",
      description:
        "A showcase of stunning fashion trends and designs by top designers.",
      photos: [
        "Holi/holi1.jpg",
        "Holi/holi2.jpg",
        "Holi/holi3.jpg",
        "Holi/holi4.jpg",
        "Holi/holi5.jpg",
      ], // Using logo8 and logo9
    },
    {
      id: 6,
      image: "gallery(6).jpg",
      name: " Event Lounch",
      date: "14-Fab-2025",
      description:
        "A showcase of stunning fashion trends and designs by top designers.",
      photos: [
        "softrailevent/softtrail1.jpg",
        "softrailevent/softtrail2.jpg",
        "softrailevent/softtrail13.jpg",
        "softrailevent/softtrail3.jpg",
        "softrailevent/softtrail4.jpg",
        "softrailevent/softtrail5.jpg",
        "softrailevent/softtrail6.jpg",
        "softrailevent/softtrail7.jpg",
        "softrailevent/softtrail8.jpg",
        "softrailevent/softtrail9.jpg",
        "softrailevent/softtrail10.jpg",
        "softrailevent/softtrail11.jpg",
        "softrailevent/softtrail12.jpg",
      ],
      // Using logo8 and logo9
    },
  ];


  const images = [
    "softrailevent/softtrail5.jpg", "softrailevent/softtrail7.jpg", "annual/annual5.jpg", "Holi/holi4.jpg",
    "independend/Independence10.jpg", "diwali/diwali3.jpg", "Holi/holi5.jpg",
    "independend/Independence5.jpg", "annual/annual2.jpg", "party/party5.jpg", "softrailevent/softtrail1.jpg",
    "diwali/diwali1.jpg", "annual/annual1.jpg", "independend/Independence9.jpg", "softrailevent/softtrail10.jpg",
    "softrailevent/softtrail4.jpg", "independend/Independence12.jpg", "party/party4.jpg", "Holi/holi1.jpg",
    "softrailevent/softtrail3.jpg", "annual/annual4.jpg", "annual/annual8.jpg", "diwali/diwali2.jpg",
    "party/party2.jpg", "Holi/holi2.jpg", "annual/annual3.jpg", "independend/Independence3.jpg",
    "softrailevent/softtrail8.jpg", "softrailevent/softtrail6.jpg", "diwali/diwali4.jpg", "independend/Independence1.jpg",
    "softrailevent/softtrail9.jpg", "softrailevent/softtrail12.jpg", "softrailevent/softtrail11.jpg",
    "softrailevent/softtrail2.jpg", "annual/annual7.jpg", "Holi/holi3.jpg", "independend/Independence8.jpg",
    "party/party1.jpg", "diwali/diwali5.jpg", "independend/Independence7.jpg", "independend/Independence6.jpg",
    "softrailevent/softtrail13.jpg", "independend/Independence13.jpg", "independend/Independence2.jpg",
    "independend/Independence4.jpg", "annual/annual6.jpg", "softrailevent/softtrail3.jpg",
    "independend/Independence11.jpg"
  ];
  

  return (
    <div className="pt-16 px-[105px]">
      <div>
        <p className="font-bold text-[50px] text-center">OUR GALLERY</p>
        <p className="font-medium text-[18px] w-[50vw] m-auto text-center">
          Explore our gallery to witness a stunning collection of unforgettable
          moments and creative masterpieces.
        </p>
      </div>

      {/* Tabs */}
      <div className="flex gap-8 my-4 border-b border-gray-300">
        <p
          className={`font-semibold pb-2 cursor-pointer ${
            activeTab === "photos"
              ? "border-b-2 border-blue-500 text-blue-500"
              : "text-gray-500"
          }`}
          onClick={() => setActiveTab("photos")}
        >
          PHOTOS
        </p>
        <p
          className={`font-semibold pb-2 cursor-pointer ${
            activeTab === "videos"
              ? "border-b-2 border-blue-500 text-blue-500"
              : "text-gray-500"
          }`}
          onClick={() => setActiveTab("videos")}
        >
          VIDEOS
        </p>
      </div>

      {/* Gallery Slider (Always Visible) */}
      <div className="relative lg:my-12 mx-20">
        <button
          onClick={() => swiperRef.current?.slidePrev()}
          className="absolute -left-20 top-1/2 transform -translate-y-1/2 z-10 p-2 rounded-full hover:scale-110 transition rotate-180"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 48 48"
          >
            <path
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12.905 20.033H9.093L4.5 27.989h3.812ZM28.182 9.024v10.784h-3.811l-4.594 7.956h8.405v11.212L43.5 23.844Zm-7.75 11.009H16.62l-4.593 7.956h3.811Z"
              stroke-width="1"
            />
          </svg>
        </button>
        <button
          onClick={() => swiperRef.current?.slideNext()}
          className="absolute -right-20 top-1/2 transform -translate-y-1/2 z-10 p-2 rounded-full hover:scale-110 transition"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 48 48"
          >
            <path
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12.905 20.033H9.093L4.5 27.989h3.812ZM28.182 9.024v10.784h-3.811l-4.594 7.956h8.405v11.212L43.5 23.844Zm-7.75 11.009H16.62l-4.593 7.956h3.811Z"
              stroke-width="1"
            />
          </svg>
        </button>

        {/* Swiper (Gallery) */}
        <Swiper
          className="relative h-[250px]"
          effect="coverflow"
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={2.43}
          spaceBetween={85}
          loop={true}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2.5,
            slideShadows: false,
          }}
          initialSlide={1}
          onSwiper={(swiper) => (swiperRef.current = swiper)}
        >
          {events.map((event) => (
            <SwiperSlide
              key={event.id}
              className="relative w-[420px] h-[240px] transition-transform duration-300 ease-in-out scale-100 group"
              onClick={() => setSelectedEvent(event)} // Update content below
            >
              <img
                className="w-full h-full object-cover rounded-lg cursor-pointer"
                src={event.image}
                alt={event.name}
              />
              <div className="absolute bottom-0 left-0 w-full bg-black bg-opacity-60 text-white px-5 py-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <p className="font-semibold">{event.name}</p>
                <p className="font-medium text-[10px]">{event.date}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {/* Content Below Gallery (Changes on Selection) */}
      <div>
        {activeTab === "photos" && (
          <div>
            {selectedEvent ? (
              // Selected Event Details
              <div>
                <div className="mt-6 flex justify-between">
                  <div>
                    <p className="text-[24px] font-semibold">
                      {selectedEvent.name}
                    </p>
                    <p className="text-sm font-medium">{selectedEvent.date}</p>
                  </div>
                  <div>
                    <button
                      onClick={() => setSelectedEvent(null)}
                      className="mb-4 px-4 py-2 text-sm font-semibold text-[#005AE6]"
                    >
                      VIEW ALL
                    </button>
                  </div>
                  {/* <p className="mt-2 text-gray-700">{selectedEvent.description}</p> */}
                </div>

                {/* Unique Images for Each Event */}
                {selectedEvent && (
                  <div className="my-6 space-y-6">
                    {selectedEvent.photos
                      .reduce((sections, _, index) => {
                        if (index % 5 === 0) sections.push([]);
                        sections[sections.length - 1].push(
                          selectedEvent.photos[index]
                        );
                        return sections;
                      }, [])
                      .map((section, sectionIndex) => (
                        <div
                          key={sectionIndex}
                          className="flex justify-between"
                        >
                          {/* Large Image (First in the section) */}
                          <div
                            className="bg-gray-500 w-[520px] h-[380px] overflow-hidden cursor-pointer"
                            onClick={() => setModalImage(section[0])}
                          >
                            <img
                              src={section[0]}
                              className="w-full h-full object-cover"
                              alt="Event Image"
                            />
                          </div>

                          {/* Grid of Smaller Images */}
                          <div className="space-y-3">
                            {section
                              .slice(1)
                              .reduce((rows, photo, index) => {
                                if (index % 2 === 0) rows.push([]);
                                rows[rows.length - 1].push(photo);
                                return rows;
                              }, [])
                              .map((row, rowIndex) => (
                                <div key={rowIndex} className="flex gap-4">
                                  {row.map((photo, colIndex) => (
                                    <div
                                      key={colIndex}
                                      className="w-[255px] h-[184px] bg-slate-500 cursor-pointer"
                                      onClick={() => setModalImage(photo)}
                                    >
                                      <img
                                        src={photo}
                                        className="w-full h-full object-cover"
                                        alt="Event Image"
                                      />
                                    </div>
                                  ))}
                                </div>
                              ))}
                          </div>
                        </div>
                      ))}
                  </div>
                )}

                {/* Fullscreen Image Modal */}
                {modalImage && (
                  <div
                    className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
                    onClick={() => setModalImage(null)}
                  >
                    <img
                      src={modalImage}
                      className="max-w-[90%] max-h-[90%] rounded-lg"
                      alt="Full Size Event Image"
                    />
                  </div>
                )}
              </div>
            ) : (
              // Default Content Below Gallery
              <div className="flex flex-wrap gap-6 p-6 yoyoy">
              {images.reduce((sections, _, i) => {
                if (i % 5 === 0) sections.push(images.slice(i, i + 5));
                return sections;
              }, []).map((section, index) => (
                <div key={index} className="flex justify-between w-full">
                  {/* Large Image */}
                  {section[0] && (
                    <div 
                      className="bg-gray-500 w-[520px] h-[380px] overflow-hidden cursor-pointer"
                      onClick={() => setModalImage(section[0])} // Open image in modal
                    >
                      <img src={section[0]} className="w-full h-full object-cover" alt="Event" />
                    </div>
                  )}
            
                  {/* Grid of Smaller Images */}
                  <div className="space-y-3">
                    {[1, 3].map((startIdx) => (
                      <div key={startIdx} className="flex gap-4">
                        {section.slice(startIdx, startIdx + 2).map((photo, idx) => (
                          <div 
                            key={idx} 
                            className="w-[255px] h-[184px] bg-slate-500 cursor-pointer"
                            onClick={() => setModalImage(photo)} // Open image in modal
                          >
                            <img src={photo} className="w-full h-full object-cover" alt="Event" />
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>

            
            )}
            {modalImage && (
                  <div
                    className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
                    onClick={() => setModalImage(null)}
                  >
                    <img
                      src={modalImage}
                      className="max-w-[90%] max-h-[90%] rounded-lg"
                      alt="Full Size Event Image"
                    />
                  </div>
                )}
          </div>
        )}

        {activeTab === "videos" && (
          <div className="lala">
            <p className="text-center text-gray-700">
              Videos content goes here.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
